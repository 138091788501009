import { css } from 'styled-components';
import styleConstants from '../../constants/style/styleConstants';

const resetButton = css`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  ${styleConstants.flexBox()};
`;

export { resetButton };
