/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/19/24
 * 내용 : root router
 */

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MoveMain from './components/redirect/MoveMain';
import HomePage from './containers/home/HomePage';

function Root(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<MoveMain />} />
      </Routes>
    </BrowserRouter>
  );
}

Root.propTypes = {};
Root.defaultProps = {};

export default Root;
