import styled from 'styled-components';
import styleConstants from '../../../constants/style/styleConstants';
import { CommonResetStyle } from '../../common/resetStyle';

const FooterNavWrapStyled = styled.nav`
  margin: 0 auto;
  ${CommonResetStyle};

  .footer-nav-wrap {
    ${styleConstants.flexBox('space-between')};
    gap: 30px;
  }

  .nav-item {
  }

  .btn-nav-link {
    padding: 10px 0;
    color: #352b2c;
    ${styleConstants.flexBox()};

    text-align: center;
    /* Title/title4 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    &.bold {
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 480px) {
    .footer-nav-wrap {
      flex-direction: column;
      gap: 4px;
    }

    .btn-nav-link {
      font-size: 10px;
      padding: 0;
    }
  }
`;

export { FooterNavWrapStyled };
