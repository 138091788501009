const FOOTER_INFO_LIST = [
  {
    order: 1,
    title: '상호명',
    content: '(주)빅브라운',
  },
  {
    order: 2,
    title: '대표자',
    content: '김용환',
  },
  {
    order: 3,
    title: '주소',
    content: '서울특별시 강남구 테헤란로 441 (삼성동, 송암빌딩)',
  },
  {
    order: 4,
    title: '연락처',
    content: '02-557-6565',
    url: 'tel:02-557-6565',
  },
  {
    order: 5,
    title: '이메일',
    content: 'service@biigbrown.co.kr',
    url: 'mailto:service@biigbrown.co.kr',
  },
  {
    order: 6,
    title: '사업자등록번호',
    content: '606-87-02388',
  },
];

Object.freeze(FOOTER_INFO_LIST);

export { FOOTER_INFO_LIST };
