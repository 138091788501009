/**
 * 제작 : 한승현
 * 날짜 : 2023/02/28
 * 내용 : footerContent
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FooterContentWrapStyled } from '../../../stylesheets/footer/content/footerContent';
import IconArrow from '../../icons/IconArrow';
import classNames from 'classnames';
import { FOOTER_INFO_LIST } from '../../../constants/footer/footerInfoList';

function FooterContent() {
  const [isShow, setIsShow] = useState(false);

  return (
    <FooterContentWrapStyled className="footer-content">
      <div className="footer-info-wrap">
        <button
          className="btn-footer-more"
          onClick={() => {
            setIsShow(!isShow);
          }}
        >
          (주)빅브라운 사업자 정보
          <IconArrow direction={isShow ? 180 : 0} />
        </button>
        <dl
          className={classNames('footer-info-list', {
            hide: !isShow,
          })}
        >
          {FOOTER_INFO_LIST.map((_o) => (
            <div
              key={`footer-info-${_o.order}`}
              className="footer-info-list-item"
            >
              <dt>{_o.title}</dt>
              <dd>
                {_o?.url ? <Link to={_o.url}>{_o.content}</Link> : _o.content}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </FooterContentWrapStyled>
  );
}

export default FooterContent;
