const STYLE_CONSTANTS = {
  flexBox: (direction = 'row', justify = 'center', align = 'center') => `
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
  `,
  rotate: (_direction = 0) => `rotate(${_direction}deg)`,
  ROTATE_DIRECTION: {
    UP: 0,
    DOWN: 180,
    LEFT: 270,
    RIGHT: 90,
  },
  MIN_SCALE: 1,
  DEFAULT_OPACITY: 1,
  MAIN_HEADER_HEIGHT: 60,
  MAIN_HEADER_HEIGHT_MIN: 40,
  MOBILE_PADDING: 10,
};

export default STYLE_CONSTANTS;
