const NumberLib = {
  /**
     * _total 에 _value 의 % 반환
     @param params {{_total: number, _value: number}}
     * @return {number}
     */
  getPercentage: ({ _value, _total, percentage = 100 }) => {
    return Math.floor((_value / _total) * percentage);
  },

  /**
   * 숫자를 받아서 소숫점 길이를 맞춰서 반환
   * @param _number {number}
   * @param _length {number}
   * @param _defaultNum {number} optional
   * @return {number}
   * ex)
   * getDecimalByLength(1234, 1); // 123.4
   * getDecimalByLength(1234, 5); // 0.01234
   */
  getDecimalByLength: (_number, _length, _defaultNum = 0) => {
    // `_length`의 길이만큼 소수점으로 나누기 위해 10의 `_length` 제곱을 계산
    const divisor = Math.pow(10, _length);

    // `_number`를 `divisor`로 나누어 소수로 만들기
    const decimalNumber = _number / divisor;

    // 선택적으로 `_defaultNum` 값을 더함
    return decimalNumber + _defaultNum;
  },
};

export default NumberLib;
