import styled from 'styled-components';
import styleConstants from '../../constants/style/styleConstants';

const MainHeaderWrapStyled = styled.header`
  max-width: 100%;
  width: 100%;
  height: ${styleConstants.MAIN_HEADER_HEIGHT}px;
  ${styleConstants.flexBox()};

  background-color: rgba(12, 13, 29, ${(props) => props.$opacity});
  transition: background-color 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  button {
    border: none;
    background: none;
    cursor: pointer;
    ${styleConstants.flexBox()};
  }

  @media screen and (max-width: 480px) {
    height: ${styleConstants.MAIN_HEADER_HEIGHT_MIN}px;
  }
`;

export { MainHeaderWrapStyled };
