import React from 'react';
import AlignCenter from '../../containers/center/AlignCenter';

const SCROLL_CONTENT_LIST = [
  {
    breakPoint: {
      increasePoint: [0, 0],
      decreasePoint: [400, 800],
      isIncrease: false,
      isDecrease: true,
    },
    component: (
      <>
        <AlignCenter>
          <AlignCenter.Before>MORE THAN BIG</AlignCenter.Before>
          BIIG BROWN
        </AlignCenter>
      </>
    ),
  },
  {
    breakPoint: {
      increasePoint: [800, 1200],
      decreasePoint: [1200, 1600],
      isIncrease: true,
      isDecrease: true,
    },
    component: (
      <>
        <AlignCenter>
          <AlignCenter.Before>MORE THAN PRESENT</AlignCenter.Before>
          BIIG FUTURE
          <AlignCenter.After>
            {[
              '빅브라운이 만들어 나갈 미래는',
              '세상의 변화에 발 맞추어 가며',
              '사람의 반복 업무를 기술로 대체하고',
              '사람은 더욱 큰 일을 할 수 있도록 하는 미래입니다.',
              '',
              '단순히 기술과 속도로만 채워지는 미래가 아닌',
              '공상과 기술을 통해 일상의 변화를 만들어내는',
              '빅브라운만의 최초 발자국을 새기겠습니다.',
            ].map((item, index) => (
              <div key={`${item}-${index}`}>{item}</div>
            ))}
          </AlignCenter.After>
        </AlignCenter>
      </>
    ),
  },
  {
    breakPoint: {
      increasePoint: [1600, 2000],
      decreasePoint: [2400, 2800],
      isIncrease: true,
      isDecrease: false,
    },
    component: (
      <>
        <AlignCenter>
          <AlignCenter.Before>GAME CHANGER</AlignCenter.Before>
          BIIG SOLUTION
          <AlignCenter.After>
            {[
              '공상과 기술의 조화로',
              '새로운 미래를 열어가는 솔루션.',
              '',
              '창의적인 생각과',
              '생각을 현실로 이루어 내는 기술로',
              '해결책을 제시합니다.',
            ].map((item, index) => (
              <div key={`${item}-${index}`}>{item}</div>
            ))}
          </AlignCenter.After>
        </AlignCenter>
      </>
    ),
  },
];

Object.freeze(SCROLL_CONTENT_LIST);

export default SCROLL_CONTENT_LIST;
