/**
 * 제작 : HanSeungHyeon
 * 날짜 : 9/13/24
 * 내용 :
 */

import React from 'react';

function AlignCenterBefore({ children }) {
  return (
    <>
      <div className={'main__before'}>{children}</div>
    </>
  );
}

AlignCenterBefore.propTypes = {};

export default AlignCenterBefore;
