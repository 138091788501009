/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/18/24
 * 내용 :
 */

import NumberLib from '../../libs/number/NumberLib';

const DEFAULT_OPACITY_SCALE = 100;

function useOpacityHandler() {
  /**
   * 스크롤에 따라 투명도 증가
   * @param params {{_value: number, _total: number}}
   * @param params._value {number} 현재 스크롤
   * @param params._total {number} 기준 스크롤
   * @return {number}
   */
  const increaseOpacity = ({ _value, _total }) => {
    const percentage = NumberLib.getPercentage({ _value, _total });

    const opacity = percentage / DEFAULT_OPACITY_SCALE;

    return opacity > 1 ? 1 : opacity < 0 ? 0 : opacity;
  };

  /**
   * 스크롤에 따라 투명도 감소
   * @param params {{_value: number, _total: number}}
   * @param params._value {number} 현재 스크롤
   * @param params._total {number} 기준 스크롤
   * @return {number}
   */
  const decreaseOpacity = ({ _value, _total }) => {
    const percentage = NumberLib.getPercentage({ _value, _total });

    const opacity =
      (DEFAULT_OPACITY_SCALE - percentage) / DEFAULT_OPACITY_SCALE;

    return opacity > 1 ? 1 : opacity < 0 ? 0 : opacity;
  };

  return { increaseOpacity, decreaseOpacity };
}

useOpacityHandler.propTypes = {};
useOpacityHandler.defaultProps = {};

export default useOpacityHandler;
