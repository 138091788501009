import styled from 'styled-components';
import STYLE_CONSTANTS from '../../constants/style/styleConstants';

const GreetingWrapStyled = styled.div`
  //width: 100%;
  min-height: 100vh;
  padding: 100px 0;
  background-color: #181a38;
  ${STYLE_CONSTANTS.flexBox()};

  .greeting-wrap {
    padding: 50px 70px;

    margin: 0 auto;

    border-radius: 4px;
    border: 1px solid rgba(191, 191, 191, 0.36);
    background: rgba(255, 255, 255, 0.1);

    ${STYLE_CONSTANTS.flexBox('column', 'center', 'stretch')};
    gap: 60px;
  }

  .content-wrap {
    ${STYLE_CONSTANTS.flexBox('row', 'flex-start', 'flex-start')};
    gap: 43px;
  }

  .title-wrap {
    flex: 122px 0 0;
  }

  .sub-title {
    color: #f6f6f6;

    /* Title/title6 */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

  .main-title {
    color: #fff;

    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 137.5% */

    margin-bottom: 30px;
  }

  .message-wrap {
    flex: 1;
    ${STYLE_CONSTANTS.flexBox('column', 'flex-start', 'stretch')};
    gap: 1.5rem;

    color: #fff;

    /* Mid/mid7 */
    font-family: 'IBM Plex Sans KR';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    word-break: keep-all;
  }

  .btn-visit-hoo {
    margin: 0 auto;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.2);

    display: flex;
    //width: 525px;
    padding: 14px 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    color: #fff;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

  @media screen and (max-width: 768px) {
    padding: 60px 20px;

    .greeting-wrap {
      gap: 3rem;
      padding: 20px;
    }

    .content-wrap {
      ${STYLE_CONSTANTS.flexBox('column', 'center', 'stretch')};
      gap: 2rem;
    }

    .message-wrap {
      flex: 1;

      br {
        display: none;
      }
    }

    .btn-visit-hoo {
      width: 60vw;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 60px ${STYLE_CONSTANTS.MOBILE_PADDING}px;

    .greeting-wrap {
      gap: 3rem;
      padding: ${STYLE_CONSTANTS.MOBILE_PADDING * 2}px
        ${STYLE_CONSTANTS.MOBILE_PADDING}px 3rem;
    }

    .title-wrap {
      ${STYLE_CONSTANTS.flexBox('column', 'center', 'center')};
    }

    .logo-square {
      order: 1;
      margin-bottom: 20px;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    .sub-title {
      order: 2;
    }

    .main-title {
      order: 3;
      margin-bottom: 0;
    }

    .message-wrap {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
    }

    .btn-visit-hoo {
      width: 80%;
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;

export { GreetingWrapStyled };
