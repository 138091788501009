/**
 * 제작 : 한승현
 * 날짜 : 2023/02/28
 * 내용 : footer
 */

import React from 'react';
import { FooterWrapStyled } from '../../stylesheets/footer/footer';
import FooterNav from './nav/FooterNav';
import FooterContent from './content/FooterContent';

function Footer() {
  return (
    <FooterWrapStyled id="footer">
      <div className="footer-wrap">
        <FooterNav />
        <FooterContent />
        <div className="footer-copy">
          &copy; BIIGBROWN. All rights reserved.
        </div>
      </div>
    </FooterWrapStyled>
  );
}

export default Footer;
