/**
 * 제작 : 한승현
 * 날짜 : 2023/02/25
 * 내용 : visual area
 */

import React from 'react';
import { VisualWrapStyled } from '../../stylesheets/visual/visual';
import backgroundImg from '../../images/visual/about_biigbrown_main_2.webp';
import NumberLib from '../../libs/number/NumberLib';
import STYLE_CONSTANTS from '../../constants/style/styleConstants';
import classNames from 'classnames';
import useScroll from '../hooks/useScroll';
import AboutBiigScroll from './AboutBiigScroll'; // 이미지 배율

// 이미지 배율
const IMG_SCALE_FLOOR = 4;
// 기본 투명도
const BASE_OPACITY = 0.3;

function Visual({ opacity }) {
  const { scrollPosition } = useScroll();

  return (
    <VisualWrapStyled background={backgroundImg}>
      <div
        className={'background-container'}
        style={{
          transform: `scale(${NumberLib.getDecimalByLength(
            scrollPosition.y,
            IMG_SCALE_FLOOR,
            STYLE_CONSTANTS.MIN_SCALE
          )})`,
        }}
      />

      {/* opacity layer */}
      <div
        className={classNames('background-filter', {
          // white: opacity >= 1,
        })}
        style={{
          opacity: opacity < BASE_OPACITY ? BASE_OPACITY : opacity,
        }}
      />

      {/* 스크롤 안내 아이콘 */}
      <AboutBiigScroll />
    </VisualWrapStyled>
  );
}

Visual.propTypes = {};
Visual.defaultProps = {};

export default Visual;
