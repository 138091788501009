/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/20/24
 * 내용 :
 */

import { useContext } from 'react';
import { WindowSizeContext } from '../../contexts/WindowSizeContext';

function useWindowSize(props) {
  return useContext(WindowSizeContext);
}

useWindowSize.propTypes = {};
useWindowSize.defaultProps = {};

export default useWindowSize;
