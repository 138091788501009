/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/18/24
 * 내용 : 메인 헤더
 */

import React from 'react';
import { MainHeaderWrapStyled } from '../../stylesheets/header/mainHeader';
import MainLogo from '../logo/MainLogo';
import useScroll from '../hooks/useScroll';
import SCROLL_TO_BEHAVIOR from '../../constants/scroll/scroll';

function MainHeader({ opacity }) {
  const { handleScroll } = useScroll();

  /**
   * 최상단으로 스크롤 이동
   */
  const onClick = () => {
    handleScroll({ x: 0, y: 0, behavior: SCROLL_TO_BEHAVIOR.SMOOTH });
  };

  return (
    <MainHeaderWrapStyled $opacity={opacity ? 1 : 0}>
      <h1>
        <button type="button" onClick={onClick}>
          <MainLogo />
        </button>
      </h1>
    </MainHeaderWrapStyled>
  );
}

MainHeader.propTypes = {};
MainHeader.defaultProps = {};

export default MainHeader;
