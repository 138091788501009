import styled from 'styled-components';
import styleConstants from '../../constants/style/styleConstants';

const FooterWrapStyled = styled.footer`
  width: 100%;
  background-color: #fff;
  padding: 30px 0 40px;

  .footer-wrap {
    max-width: 1200px;
    margin: 0 auto;
    ${styleConstants.flexBox('column', 'flex-start', 'center')};
    gap: 20px;
  }

  .footer-copy {
    color: #352b2c;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  @media screen and (max-width: 480px) {
    .footer-wrap {
      gap: 8px;
    }

    .footer-copy {
      font-size: 10px;
    }
  }
`;

export { FooterWrapStyled };
