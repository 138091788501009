import styled from 'styled-components';
import styleConstants from '../../constants/style/styleConstants';

const VisualWrapStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  ${styleConstants.flexBox()};

  .background-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -100;

    background: center / cover no-repeat url(${(props) => props.background});
  }

  .background-filter {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -10;

    &.white {
      background-color: #fff;
    }
  }

  .fixed-wrap {
    ${styleConstants.flexBox()};
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
  }

  .scroll-icon-wrap {
    position: fixed;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 100;

    opacity: 0.6;

    &.hide {
      display: none;
    }
  }

  .scroll-icon-wrap__text {
    color: #fff;
    text-align: center;

    /* Mid/mid5 */
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
  }

  .scroll-icon-wrap__icon {
    ${styleConstants.flexBox()};

    @keyframes dropWithOpacityAnimation {
      0% {
        transform: translateY(-10px);
        opacity: 0;
      }
      20% {
        transform: translateY(0);
        opacity: 1;
      }
      60% {
        transform: translateY(5px);
      }
      80% {
        opacity: 0;
        transform: translateY(15px);
      }
      100% {
        opacity: 0;
      }
    }

    svg {
      animation: dropWithOpacityAnimation infinite 1200ms;
    }
  }
`;

export { VisualWrapStyled };
