/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/20/24
 * 내용 :
 */

import React from 'react';
import classNames from 'classnames';
import IconArrow from '../icons/IconArrow';
import useScroll from '../hooks/useScroll';
import SCROLL_CONTENT_LIST from '../../constants/scroll/scroll_content';

function AboutBiigScroll(props) {
  const { scrollPosition } = useScroll();

  return (
    <div
      className={classNames('scroll-icon-wrap', {
        hide:
          SCROLL_CONTENT_LIST.at(-1).breakPoint.decreasePoint[1] <=
          scrollPosition.y,
      })}
    >
      <p className="scroll-icon-wrap__text">About Biig</p>
      <div className="scroll-icon-wrap__icon">
        <IconArrow fill="#fff" />
      </div>
    </div>
  );
}

AboutBiigScroll.propTypes = {};
AboutBiigScroll.defaultProps = {};

export default AboutBiigScroll;
