import styled from 'styled-components';

const HomePageStyled = styled.div`
  --center-box-width: 100vw;
  --center-box-height: 100vh;
  --center-box-background-color: transparent;
  --center-box-font-color: #fff;

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export { HomePageStyled };
