import styled from 'styled-components';
import STYLE_CONSTANTS from '../../../constants/style/styleConstants';

const AlignCenterStyled = styled.div`
  text-align: center;
  word-break: keep-all;
  width: var(--center-box-width);
  height: var(--center-box-height);
  background-color: var(--center-box-background-color);
  color: var(--center-box-font-color);

  .main {
    position: absolute;
    width: 100vw;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.75rem;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
  }

  .main__before {
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: calc(100% + 20px);
    font-size: 1.125rem;

    font-weight: 400;
  }

  .main__after {
    width: 100vw;
    position: absolute;
    left: 0;
    top: calc(100% + 20px);
    font-size: 1.125rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;

    div {
      min-height: 1rem;
    }
  }

  @media screen and (max-width: 480px) {
    .main,
    .main__before,
    .main__after {
      padding: 0 ${STYLE_CONSTANTS.MOBILE_PADDING}px;
    }

    .main {
      font-size: 30px;
    }

    .main__after {
      font-size: 16px;

      div {
        min-height: 10px;
      }
    }
  }
`;

export { AlignCenterStyled };
