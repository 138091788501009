/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/20/24
 * 내용 :
 */

import React, { createContext, useEffect, useState } from 'react';
import SCROLL_TO_BEHAVIOR from '../constants/scroll/scroll';

export const ScrollContext = createContext();

export const ScrollContextProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState({
    x: window?.scrollX || 0,
    y: window?.scrollY || 0,
  });

  /**
   * 스크롤 변화 값 저장
   * Math.floor, Math.round, Math.ceil 중 round 선택 함
   */
  const setScroll = () => {
    setScrollPosition({
      x: window.scrollX,
      y: window.scrollY,
    });
  };

  /**
   * 스크롤 이벤트 처리
   */
  useEffect(() => {
    window.addEventListener('scroll', setScroll);

    return () => {
      window.removeEventListener('scroll', setScroll);
    };
  }, []);

  /**
   * 터치 이벤트 처리
   */
  // useEffect(() => {
  //   window.addEventListener('touchmove', test, { passive: true });
  //
  //   return () => {
  //     window.removeEventListener('touchmove', test);
  //   };
  // }, []);

  /**
   * 스크롤 이동
   * @param options {{x:number, y:number, behavior: SCROLL_TO_BEHAVIOR}}
   * {x: number, y: number, behavior: SCROLL_TO_BEHAVIOR}
   */
  const handleScroll = (options) => {
    const { x = 0, y = 0, behavior = SCROLL_TO_BEHAVIOR.AUTO } = options;

    window.scrollTo({ top: x, left: y, behavior });
  };

  return (
    <>
      <ScrollContext.Provider
        value={{
          scrollPosition,
          handleScroll,
        }}
      >
        {children}
      </ScrollContext.Provider>
    </>
  );
};

ScrollContextProvider.propTypes = {};
ScrollContextProvider.defaultProps = {};
