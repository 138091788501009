/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/19/24
 * 내용 : redirect main
 */

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function MoveMain(props) {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * "/"로 리다이렉트
   */
  useEffect(() => {
    if (location.pathname !== '/') {
      navigate('/');
    }
  }, [location, navigate]);

  return <></>;
}

MoveMain.propTypes = {};
MoveMain.defaultProps = {};

export default MoveMain;
