/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/20/24
 * 내용 :
 */

import React, { useEffect, useState } from 'react';
import { OpacityBoxStyled } from '../../stylesheets/containers/opacity/opacityBox';
import useOpacityHandler from '../../components/hooks/useOpacityHandler';
import useScroll from '../../components/hooks/useScroll';
import PropTypes, { oneOfType } from 'prop-types';

function OpacityBox({ children, index, content }) {
  const { increaseOpacity, decreaseOpacity } = useOpacityHandler();
  const { scrollPosition } = useScroll();
  const { increasePoint, decreasePoint, isIncrease, isDecrease } =
    content.breakPoint;
  const [opacity, setOpacity] = useState(+!increasePoint[0]);

  const { y } = scrollPosition;

  useEffect(() => {
    if (isIncrease) {
      if (y < increasePoint[0]) {
        return setOpacity(0);
      }

      if (y > increasePoint[0] && y < increasePoint[1]) {
        return setOpacity(
          increaseOpacity({
            _value: y - increasePoint[0],
            _total: increasePoint[1] - increasePoint[0],
          })
        );
      }
    }

    if (isDecrease) {
      if (y < decreasePoint[0]) {
        return setOpacity(1);
      }
      if (y > decreasePoint[1]) {
        return setOpacity(0);
      }
      if (y > decreasePoint[0] && y < decreasePoint[1]) {
        return setOpacity(
          decreaseOpacity({
            _value: y - decreasePoint[0],
            _total: decreasePoint[1] - decreasePoint[0],
          })
        );
      }
    }
  }, [
    decreaseOpacity,
    decreasePoint,
    increaseOpacity,
    increasePoint,
    isDecrease,
    isIncrease,
    y,
  ]);

  return <OpacityBoxStyled style={{ opacity }}>{children}</OpacityBoxStyled>;
}

OpacityBox.propTypes = {
  children: oneOfType([PropTypes.array, PropTypes.object]),
  index: PropTypes.number,
  content: PropTypes.object,
};
OpacityBox.defaultProps = {};

export default OpacityBox;
