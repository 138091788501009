/**
 * 제작 : HanSeungHyeon
 * 날짜 : 9/13/24
 * 내용 :
 */

import React from 'react';
import { AlignCenterStyled } from '../../stylesheets/containers/center/alignCenter';
import PropTypes from 'prop-types';
import AlignCenterMain from './AlignCenterMain';
import AlignCenterAfter from './AlignCenterAfter';
import AlignCenterBefore from './AlignCenterBefore';

function AlignCenter({ children, ...rest }) {
  return (
    <AlignCenterStyled {...rest}>
      <AlignCenterMain>{children}</AlignCenterMain>
    </AlignCenterStyled>
  );
}

AlignCenter.Before = AlignCenterBefore;
AlignCenter.After = AlignCenterAfter;

AlignCenter.propTypes = {
  children: PropTypes.node,
};

export default AlignCenter;
