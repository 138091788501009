/**
 * 제작 : HanSeungHyeon
 * 날짜 : 9/13/24
 * 내용 :
 */

import React from 'react';
import PropTypes from 'prop-types';

function AlignCenterAfter({ children }) {
  return <div className={'main__after'}>{children}</div>;
}

AlignCenterAfter.propTypes = {
  children: PropTypes.node,
};

export default AlignCenterAfter;
