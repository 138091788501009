/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/19/24
 * 내용 : home page
 */

import React from 'react';
import { HomePageStyled } from '../../stylesheets/containers/home/home';
import HomeProviders from './HomeProviders';
import HomeContents from './HomeContents';

function HomePage(props) {
  return (
    <HomeProviders>
      <HomePageStyled>
        <HomeContents />
      </HomePageStyled>
    </HomeProviders>
  );
}

HomePage.propTypes = {};
HomePage.defaultProps = {};

export default HomePage;
