/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/19/24
 * 내용 :
 */

import React from 'react';
import { GreetingWrapStyled } from '../../stylesheets/greeting/greeting';
import SquareLogo from '../logo/SquareLogo';
import { BASE_URL } from '../../constants/url/baseUrl';

function Greeting() {
  return (
    <GreetingWrapStyled>
      <div className="greeting-wrap">
        <div className="content-wrap">
          <div className="title-wrap">
            <div className="sub-title">Message</div>
            <div className="main-title">대표의 말</div>
            <div className="logo-square">
              <SquareLogo />
            </div>
          </div>

          <div className="message-wrap">
            <p>
              우리는,
              <br /> 세상의 정해진 규칙에 때로는 저항하며
              <br /> 여전히 아날로그 적이고 고리타분한 서비스를 제공하는 시장에
              과감히 도전을 하겠습니다.
            </p>

            <p>
              어떤 시장이든 그 시장의 해석과 접근은 끊임없이 새로워야 합니다.
              <br /> 그리고 어떤 시장의 환경이든 그 안에서의 변화는 지속되어야
              한다고 생각합니다.
            </p>

            <p>
              빅브라운은 개선이 필요한 시장을 특유의 안목으로 발굴해 내며
              <br /> 탁월한 아이디어와 기술로 문제적 시장의 환경을 변화시키고
              <br /> 새로운 무언가를 제안하고 창조해내는 회사입니다.
            </p>

            <p>
              우리의 아이디어와 기술이
              <br /> 데이터를 기반으로 만들어졌든
              <br /> 뛰어난 직관으로 찾아냈든
              <br /> 그것으로 시장을 더욱 효율적이고 효과적으로 변화시키고자
              합니다.
            </p>
          </div>
        </div>

        <div className="button-wrap">
          <a href={BASE_URL.HOO} rel="noreferrer" className="btn-visit-hoo">
            빅브라운의 첫 번째 도전
          </a>
        </div>
      </div>
    </GreetingWrapStyled>
  );
}

Greeting.propTypes = {};
Greeting.defaultProps = {};

export default Greeting;
