import { BASE_URL } from '../url/baseUrl';

const FOOTER_NAV_LIST = [
  {
    order: 1,
    label: '이용약관',
    url: `${BASE_URL.HOO}terms/TermsOfUse`,
  },
  {
    order: 2,
    label: '개인정보처리방침',
    url: `${BASE_URL.HOO}terms/PrivacyPolicy`,
    className: 'bold',
  },
  {
    order: 3,
    label: '위치기반서비스 이용약관',
    url: `${BASE_URL.HOO}terms/TermsOfUseForLocation`,
    className: 'bold',
  },
  {
    order: 4,
    label: '회사소개',
    url: BASE_URL.ABOUT,
  },
  {
    order: 5,
    label: '제휴제안',
    url: `${BASE_URL.HOO}alliance`,
  },
];

Object.freeze(FOOTER_NAV_LIST);

export { FOOTER_NAV_LIST };
