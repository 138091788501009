/**
 * 제작 : HanSeungHyeon
 * 날짜 : 7/1/24
 * 내용 : home contexts providers
 */

import React from 'react';
import { WindowSizeProvider } from '../../contexts/WindowSizeContext';
import { ScrollContextProvider } from '../../contexts/ScrollContext';

function HomeProviders({ children }) {
  return (
    <WindowSizeProvider>
      <ScrollContextProvider>{children}</ScrollContextProvider>
    </WindowSizeProvider>
  );
}

HomeProviders.propTypes = {};
HomeProviders.defaultProps = {};

export default HomeProviders;
