import Root from './Root';
import './stylesheets/common/main.css';

function App() {
  return (
    <div className="App">
      <Root />
    </div>
  );
}

export default App;
