/**
 * 제작 : 한승현
 * 날짜 : 2023/02/28
 * 내용 : FooterNav area
 */

import React from 'react';
import { FooterNavWrapStyled } from '../../../stylesheets/footer/nav/footerNav';
import { FOOTER_NAV_LIST } from '../../../constants/footer/footerNavList';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

function FooterNav() {
  return (
    <FooterNavWrapStyled className="footer-nav">
      <ul className="footer-nav-wrap">
        {FOOTER_NAV_LIST.map((_o) => (
          <li key={`footer-nav-${_o.order}`} className="nav-item">
            <Link
              to={_o.url}
              className={classNames('btn-nav-link', {
                [_o?.className]: !!_o?.className,
              })}
            >
              {_o.label}
            </Link>
          </li>
        ))}
      </ul>
    </FooterNavWrapStyled>
  );
}

export default FooterNav;
