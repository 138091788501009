/**
 * 제작 : HanSeungHyeon
 * 날짜 : 9/13/24
 * 내용 :
 */

import React from 'react';

function AlignCenterMain({ children }) {
  return <div className="main">{children}</div>;
}

AlignCenterMain.propTypes = {};

export default AlignCenterMain;
