import styled from 'styled-components';
import { resetButton } from '../../common/buttonStyle';
import styleConstants from '../../../constants/style/styleConstants';

const FooterContentWrapStyled = styled.div`
  max-width: 1200px;

  .footer-info-wrap {
    ${styleConstants.flexBox('column', 'flex-start', 'stretch')};
    gap: 2px;
  }

  .btn-footer-more {
    ${resetButton};
    gap: 2px;

    color: #5c5c5c;

    /* Title/title3 */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }

  .footer-info-list {
    max-width: 600px;
    ${styleConstants.flexBox('row', 'center', 'center')};
    flex-wrap: wrap;
    gap: 2px 20px;

    &.hide {
      display: none;
    }
  }

  .footer-info-list-item {
    ${styleConstants.flexBox('row', 'flex-start', 'center')};

    transition: all 0.3s ease-in-out;

    position: relative;

    &:not(:nth-of-type(3n))::after {
      position: absolute;
      content: '|';
      right: -10px;
      transform: translateX(50%);
    }

    color: #5c5c5c;

    /* Body/body3 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */

    dt {
      &:after {
        content: ':';
        margin-right: 2px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .btn-footer-more {
      font-size: 10px;
    }
  }
`;

export { FooterContentWrapStyled };
