/**
 * 제작 : HanSeungHyeon
 * 날짜 : 2024/06/20
 * 내용 : 스크롤 훅
 */

import { useContext } from 'react';
import { ScrollContext } from '../../contexts/ScrollContext';

const useScroll = () => {
  return useContext(ScrollContext);
};

useScroll.propTypes = {};
useScroll.defaultProps = {};

export default useScroll;
