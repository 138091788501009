/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/22/24
 * 내용 :
 */

import React from 'react';
import styleConstants from '../../constants/style/styleConstants';
import PropTypes from 'prop-types';

function IconArrow({ direction, fill }) {
  return (
    <>
      <svg
        style={{ transform: styleConstants.rotate(direction) }}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.93182 5.41334C4.2411 5.05274 4.74761 5.04696 5.06313 5.40043L8.57242 9.33172L11.9317 5.41347C12.2409 5.0528 12.7474 5.0469 13.063 5.4003C13.3786 5.75369 13.3838 6.33255 13.0745 6.69322L9.15526 11.2647C9.00675 11.4379 8.8041 11.5366 8.59191 11.539C8.37972 11.5415 8.17537 11.4475 8.02383 11.2777L3.94312 6.70627C3.62759 6.3528 3.62253 5.77394 3.93182 5.41334Z"
          fill={fill}
        />
      </svg>
    </>
  );
}

IconArrow.propTypes = {
  direction: PropTypes.number,
  fill: PropTypes.string.isRequired,
};
IconArrow.defaultProps = {
  direction: 0,
  fill: '#5c5c5c',
};

export default IconArrow;
