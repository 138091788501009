/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/19/24
 * 내용 :
 */

import React from 'react';

function SquareLogo(props) {
  return (
    <>
      <svg
        width="102"
        height="102"
        viewBox="0 0 102 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M101.22 0.335938H0.5V101.056H101.22V0.335938Z"
          fill="#1B1E52"
        />
        <path
          d="M7.56683 75.1028C7.38683 74.9128 7.29688 74.6228 7.29688 74.2228V60.8128C7.29688 60.4128 7.38684 60.1128 7.57684 59.9228C7.75684 59.7328 8.06684 59.6328 8.50684 59.6328H15.4069C17.2169 59.6328 18.5469 60.0328 19.4169 60.8228C20.2869 61.6228 20.7169 62.7228 20.7169 64.1428C20.7169 64.8428 20.5468 65.4628 20.1968 66.0328C19.8468 66.5928 19.3969 66.9928 18.8369 67.2228C20.3669 67.7128 21.1268 68.8328 21.1268 70.5828C21.1268 71.9928 20.6369 73.1428 19.6569 74.0328C18.6769 74.9228 17.2369 75.3728 15.3369 75.3728H8.50684C8.05684 75.3728 7.74683 75.2828 7.56683 75.1028ZM14.5269 65.7828C15.0069 65.7828 15.3768 65.6628 15.6368 65.4228C15.8968 65.1828 16.0269 64.8328 16.0269 64.3728C16.0269 63.8828 15.9069 63.5228 15.6569 63.3028C15.4069 63.0828 15.0268 62.9828 14.5168 62.9828H11.9669V65.7828H14.5269ZM14.9169 72.0228C15.4069 72.0228 15.7968 71.8828 16.0668 71.5928C16.3368 71.3028 16.4769 70.9328 16.4769 70.4728C16.4769 69.9428 16.3269 69.5528 16.0369 69.2928C15.7469 69.0328 15.3469 68.9028 14.8369 68.9028H11.9869V72.0328H14.9169V72.0228Z"
          fill="white"
        />
        <path
          d="M22.9263 75.1028C22.7463 74.9128 22.6562 74.6228 22.6562 74.2228V60.8128C22.6562 60.4128 22.7462 60.1128 22.9362 59.9228C23.1162 59.7328 23.4263 59.6328 23.8663 59.6328H26.1163C26.5663 59.6328 26.8862 59.7328 27.0662 59.9228C27.2462 60.1128 27.3463 60.4128 27.3463 60.8128V74.2228C27.3463 74.6228 27.2562 74.9228 27.0662 75.1028C26.8762 75.2828 26.5663 75.3828 26.1163 75.3828H23.8663C23.4163 75.3728 23.0963 75.2828 22.9263 75.1028Z"
          fill="white"
        />
        <path
          d="M29.5981 75.1028C29.4181 74.9128 29.3281 74.6228 29.3281 74.2228V60.8128C29.3281 60.4128 29.4182 60.1128 29.6082 59.9228C29.7982 59.7328 30.0981 59.6328 30.5381 59.6328H32.7881C33.2381 59.6328 33.5482 59.7328 33.7382 59.9228C33.9282 60.1128 34.0181 60.4128 34.0181 60.8128V74.2228C34.0181 74.6228 33.9282 74.9228 33.7382 75.1028C33.5482 75.2828 33.2381 75.3828 32.7881 75.3828H30.5381C30.0881 75.3728 29.7781 75.2828 29.5981 75.1028Z"
          fill="white"
        />
        <path
          d="M37.8091 73.6256C36.2991 72.2256 35.5391 70.1856 35.5391 67.4956C35.5391 64.8056 36.299 62.7656 37.819 61.3656C39.339 59.9656 41.469 59.2656 44.199 59.2656C45.389 59.2656 46.469 59.3856 47.439 59.6256C48.409 59.8656 49.2191 60.2056 49.8691 60.6356C50.0391 60.7456 50.129 60.8756 50.129 61.0256C50.129 61.1156 50.0791 61.2756 49.9691 61.4856L48.949 63.2256C48.859 63.4256 48.729 63.5256 48.579 63.5256C48.489 63.5256 48.3291 63.4756 48.1191 63.3656C47.1291 62.8556 46.039 62.6056 44.829 62.6056C43.389 62.6056 42.259 63.0156 41.439 63.8356C40.619 64.6556 40.199 65.8756 40.199 67.4956C40.199 69.1456 40.5691 70.3756 41.3091 71.1756C42.0491 71.9756 43.1091 72.3756 44.4791 72.3756C44.8191 72.3756 45.1791 72.3456 45.5591 72.2856C45.9391 72.2256 46.2391 72.1456 46.4791 72.0556V69.2256H44.4891C44.0591 69.2256 43.7591 69.1456 43.5991 68.9756C43.4391 68.8056 43.3591 68.5156 43.3591 68.0956V67.0556C43.3591 66.6556 43.4391 66.3656 43.5991 66.1956C43.7591 66.0256 44.0591 65.9456 44.4891 65.9456H49.4191C49.9491 65.9456 50.309 66.0656 50.509 66.3056C50.709 66.5456 50.8091 66.9156 50.8091 67.4056V73.0556C50.8091 73.3456 50.769 73.5756 50.699 73.7356C50.629 73.8956 50.489 74.0556 50.269 74.2056C48.849 75.2056 46.829 75.7156 44.199 75.7156C41.449 75.7256 39.3191 75.0256 37.8091 73.6256Z"
          fill="white"
        />
        <path
          d="M7.56683 93.3763C7.38683 93.1863 7.29688 92.8963 7.29688 92.4963V79.0863C7.29688 78.6863 7.38684 78.3863 7.57684 78.1963C7.75684 78.0063 8.06684 77.9062 8.50684 77.9062H15.4069C17.2169 77.9062 18.5469 78.3063 19.4169 79.0963C20.2869 79.8963 20.7169 80.9963 20.7169 82.4163C20.7169 83.1163 20.5468 83.7363 20.1968 84.3063C19.8468 84.8663 19.3969 85.2663 18.8369 85.4963C20.3669 85.9863 21.1268 87.1063 21.1268 88.8563C21.1268 90.2663 20.6369 91.4163 19.6569 92.3063C18.6769 93.1963 17.2369 93.6463 15.3369 93.6463H8.50684C8.05684 93.6563 7.74683 93.5663 7.56683 93.3763ZM14.5269 84.0663C15.0069 84.0663 15.3768 83.9463 15.6368 83.7063C15.8968 83.4663 16.0269 83.1163 16.0269 82.6562C16.0269 82.1663 15.9069 81.8063 15.6569 81.5863C15.4069 81.3663 15.0268 81.2663 14.5168 81.2663H11.9669V84.0663H14.5269ZM14.9169 90.2963C15.4069 90.2963 15.7968 90.1563 16.0668 89.8663C16.3368 89.5763 16.4769 89.2063 16.4769 88.7463C16.4769 88.2163 16.3269 87.8263 16.0369 87.5663C15.7469 87.3063 15.3469 87.1763 14.8369 87.1763H11.9869V90.3063H14.9169V90.2963Z"
          fill="white"
        />
        <path
          d="M22.9263 93.3763C22.7463 93.1863 22.6562 92.8963 22.6562 92.4963V79.0863C22.6562 78.6863 22.7462 78.3863 22.9362 78.1963C23.1162 78.0063 23.4263 77.9062 23.8663 77.9062H29.5662C31.7162 77.9062 33.2962 78.3563 34.3162 79.2463C35.3362 80.1363 35.8463 81.4763 35.8463 83.2563C35.8463 85.4663 34.9963 86.9863 33.2963 87.8163L35.5862 92.7963C35.6462 92.9163 35.6763 93.0463 35.6763 93.1663C35.6763 93.3063 35.6262 93.4163 35.5262 93.5163C35.4262 93.6063 35.2962 93.6562 35.1262 93.6562H31.8862C31.6562 93.6562 31.4763 93.6263 31.3463 93.5663C31.2163 93.5063 31.1163 93.3863 31.0563 93.2163L29.1362 88.5163H27.3563V92.4963C27.3563 92.8963 27.2662 93.1963 27.0762 93.3763C26.8862 93.5563 26.5762 93.6562 26.1262 93.6562H23.8762C23.4162 93.6562 23.0963 93.5663 22.9263 93.3763ZM29.0563 85.2063C30.4563 85.2063 31.1663 84.5563 31.1663 83.2563C31.1663 81.9463 30.5063 81.2863 29.1763 81.2863H27.3463V85.2063H29.0563Z"
          fill="white"
        />
        <path
          d="M38.9984 91.8625C37.5984 90.4325 36.8984 88.4025 36.8984 85.7825C36.8984 83.1525 37.5984 81.1325 38.9984 79.7025C40.3984 78.2725 42.4384 77.5625 45.1284 77.5625C47.8184 77.5625 49.8584 78.2725 51.2584 79.7025C52.6584 81.1325 53.3585 83.1625 53.3585 85.7825C53.3585 88.4025 52.6584 90.4325 51.2584 91.8625C49.8584 93.2925 47.8184 94.0025 45.1284 94.0025C42.4384 94.0025 40.3984 93.2925 38.9984 91.8625ZM47.8284 89.4625C48.4084 88.6425 48.6984 87.4125 48.6984 85.7825C48.6984 84.1625 48.4084 82.9425 47.8284 82.1225C47.2484 81.3025 46.3484 80.8925 45.1284 80.8925C43.9084 80.8925 43.0085 81.3025 42.4285 82.1225C41.8485 82.9425 41.5585 84.1625 41.5585 85.7825C41.5585 87.4225 41.8485 88.6425 42.4285 89.4625C43.0085 90.2825 43.9084 90.6925 45.1284 90.6925C46.3484 90.6925 47.2484 90.2825 47.8284 89.4625Z"
          fill="white"
        />
        <path
          d="M56.8375 93.4841C56.6575 93.3641 56.5375 93.1741 56.4775 92.8941L52.9575 78.6041C52.9375 78.5441 52.9375 78.4541 52.9375 78.3541C52.9375 78.0641 53.1275 77.9141 53.5175 77.9141H56.7875C57.0675 77.9141 57.2575 77.9641 57.3775 78.0541C57.4975 78.1441 57.5675 78.2941 57.5975 78.4941L58.9875 85.5841L60.8375 79.7741C60.9175 79.5241 61.0175 79.3541 61.1375 79.2641C61.2575 79.1741 61.4575 79.1241 61.7375 79.1241H63.2875C63.5675 79.1241 63.7675 79.1741 63.8975 79.2641C64.0275 79.3541 64.1275 79.5241 64.2075 79.7741L66.0576 85.5841L67.4276 78.4941C67.4576 78.2941 67.5375 78.1441 67.6575 78.0541C67.7775 77.9641 67.9775 77.9141 68.2575 77.9141H71.5275C71.9275 77.9141 72.1275 78.0641 72.1275 78.3541C72.1275 78.4641 72.1175 78.5441 72.1075 78.6041L68.5375 92.8941C68.4775 93.1741 68.3575 93.3741 68.1875 93.4841C68.0175 93.6041 67.7775 93.6541 67.4675 93.6541H65.3575C65.0475 93.6541 64.8175 93.5941 64.6475 93.4841C64.4875 93.3641 64.3675 93.1741 64.2875 92.8941L62.5275 86.7941L60.7475 92.8841C60.6675 93.1641 60.5475 93.3641 60.3775 93.4741C60.2075 93.5941 59.9775 93.6441 59.6875 93.6441H57.5775C57.2575 93.6541 57.0175 93.5941 56.8375 93.4841Z"
          fill="white"
        />
        <path
          d="M73.9575 93.3763C73.7775 93.1863 73.6875 92.8963 73.6875 92.4963V79.0663C73.6875 78.6663 73.7775 78.3663 73.9675 78.1863C74.1575 78.0063 74.4675 77.9062 74.9175 77.9062H76.7475C77.0275 77.9062 77.2475 77.9463 77.4075 78.0263C77.5675 78.1063 77.7275 78.2563 77.8775 78.4863L83.2275 86.1763V79.0863C83.2275 78.6863 83.3175 78.3863 83.5075 78.1963C83.6975 78.0063 83.9975 77.9062 84.4375 77.9062H86.6875C87.1375 77.9062 87.4475 78.0063 87.6375 78.1963C87.8275 78.3863 87.9175 78.6863 87.9175 79.0863V92.5163C87.9175 92.9163 87.8275 93.2063 87.6375 93.3863C87.4475 93.5663 87.1375 93.6562 86.6875 93.6562H84.8575C84.5675 93.6562 84.3375 93.6263 84.1875 93.5563C84.0375 93.4863 83.8775 93.3363 83.7275 93.1063L78.3775 85.4163V92.5263C78.3775 92.9263 78.2875 93.2163 78.0975 93.3963C77.9075 93.5763 77.5975 93.6663 77.1475 93.6663H74.8975C74.4475 93.6563 74.1375 93.5663 73.9575 93.3763Z"
          fill="white"
        />
        <path
          d="M22.4069 22.6641L6.29688 39.0741V50.8441C6.29688 53.5141 8.46684 55.6841 11.1368 55.6841H21.9968C31.1168 55.6841 38.5068 48.2941 38.5068 39.1741C38.5068 30.0541 31.5269 22.6641 22.4069 22.6641Z"
          fill="url(#paint0_linear_1109_252)"
        />
        <path
          d="M6.30469 39.0778V10.4678C6.30469 8.13783 8.19465 6.25781 10.5146 6.25781H24.3047C24.3047 6.25781 35.4047 7.21782 37.9547 18.9578C37.9547 18.9578 39.9547 25.2078 36.3547 30.9078C36.3547 30.9078 32.1547 22.8778 22.3947 22.6778V39.0878H6.30469V39.0778Z"
          fill="url(#paint1_linear_1109_252)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1109_252"
            x1="14.1258"
            y1="27.55"
            x2="28.4935"
            y2="55.208"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#374CA0" />
            <stop offset="0.3885" stopColor="#374DA1" />
            <stop offset="0.5285" stopColor="#3751A1" />
            <stop offset="0.6283" stopColor="#3658A4" />
            <stop offset="0.7089" stopColor="#3561A7" />
            <stop offset="0.778" stopColor="#336CAB" />
            <stop offset="0.8392" stopColor="#3179B1" />
            <stop offset="0.8946" stopColor="#2D8BB8" />
            <stop offset="0.9455" stopColor="#29A0C1" />
            <stop offset="0.9908" stopColor="#25B7C8" />
            <stop offset="1" stopColor="#1FBECB" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1109_252"
            x1="3.22629"
            y1="4.66081"
            x2="26.8273"
            y2="33.1522"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F6EB12" />
            <stop offset="0.1144" stopColor="#F3EA20" />
            <stop offset="0.2481" stopColor="#EAE73B" />
            <stop offset="0.3916" stopColor="#DBE35A" />
            <stop offset="0.5419" stopColor="#C4DC7B" />
            <stop offset="0.6976" stopColor="#A4D49B" />
            <stop offset="0.8552" stopColor="#7ACBBF" />
            <stop offset="1" stopColor="#33C3E1" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}

SquareLogo.propTypes = {};
SquareLogo.defaultProps = {};

export default SquareLogo;
