/**
 * 제작 : HanSeungHyeon
 * 날짜 : 4/18/24
 * 내용 : 빅브라운 로고
 */

import React from 'react';
import PropTypes from 'prop-types';

function MainLogo({ fill }) {
  return (
    <svg
      width="138"
      height="36"
      viewBox="0 0 138 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.621 10.9297V25.0703H124.516L119.262 17.4336H119.164V25.0703H115.453V10.9297H118.617L123.793 18.5273H123.91V10.9297H127.621Z"
        fill={fill}
      />
      <path
        d="M97.8945 25.0703L93.7344 10.9297H97.875L99.8672 19.7773H99.9844L102.309 10.9297H105.59L107.934 19.8164H108.051L110.043 10.9297H114.184L110.023 25.0703H106.449L104.008 16.9844H103.891L101.449 25.0703H97.8945Z"
        fill={fill}
      />
      <path
        d="M93.3789 18C93.3789 22.707 90.3711 25.2656 86.543 25.2656C82.6758 25.2656 79.6875 22.6875 79.6875 18C79.6875 13.293 82.6758 10.7344 86.543 10.7344C90.3711 10.7344 93.3789 13.293 93.3789 18ZM89.5508 18C89.5508 15.4023 88.4766 13.9766 86.543 13.9766C84.5898 13.9766 83.5156 15.4023 83.5156 18C83.5156 20.5977 84.5898 22.0234 86.543 22.0234C88.4766 22.0234 89.5508 20.5977 89.5508 18Z"
        fill={fill}
      />
      <path
        d="M66.8594 25.0703V10.9297H72.875C76.0391 10.9297 78.1094 12.7266 78.1094 15.7148C78.1094 17.668 77.2109 19.0547 75.6875 19.7773L78.5781 25.0703H74.5352L72.0352 20.3633H70.5703V25.0703H66.8594ZM70.5703 17.4531H72.0156C73.4219 17.4531 74.2227 16.9453 74.2227 15.7148C74.2227 14.4648 73.4219 13.8984 72.0156 13.8984H70.5703V17.4531Z"
        fill={fill}
      />
      <path
        d="M53.9062 25.0703V10.9297H59.9609C63.1836 10.9297 64.8828 12.3164 64.8828 14.4844C64.8828 16.0469 63.7695 17.2383 62.168 17.5703V17.707C63.9453 17.7656 65.4297 19.0742 65.4297 21.0859C65.4297 23.3906 63.5547 25.0703 60.4688 25.0703H53.9062ZM59.5117 22.082C60.8398 22.082 61.5039 21.5742 61.5039 20.6172C61.5039 19.582 60.7617 18.9961 59.5703 18.9961H57.6172V22.082H59.5117ZM59.2773 16.6719C60.3125 16.6719 61.0547 16.1641 61.0547 15.2656C61.0547 14.3672 60.3516 13.8594 59.3359 13.8594H57.6172V16.6719H59.2773Z"
        fill={fill}
      />
      <path
        d="M43.7734 15.5781C43.5195 14.5625 42.6797 13.9766 41.4297 13.9766C39.457 13.9766 38.3047 15.4609 38.3047 17.9805C38.3047 20.5195 39.3789 22.0234 41.4102 22.0234C43.1777 22.0234 44.0762 21.1934 44.1055 19.875H41.4883V17.2188H47.6992V19.1523C47.6992 23 45.0625 25.2656 41.3711 25.2656C37.2695 25.2656 34.4766 22.4922 34.4766 18.0195C34.4766 13.3711 37.543 10.7344 41.3516 10.7344C44.6719 10.7344 47.2305 12.7461 47.543 15.5781H43.7734Z"
        fill={fill}
      />
      <path d="M32.6641 10.9297V25.0703H28.9531V10.9297H32.6641Z" fill={fill} />
      <path d="M27.0391 10.9297V25.0703H23.3281V10.9297H27.0391Z" fill={fill} />
      <path
        d="M10.375 25.0703V10.9297H16.4297C19.6523 10.9297 21.3516 12.3164 21.3516 14.4844C21.3516 16.0469 20.2383 17.2383 18.6367 17.5703V17.707C20.4141 17.7656 21.8984 19.0742 21.8984 21.0859C21.8984 23.3906 20.0234 25.0703 16.9375 25.0703H10.375ZM15.9805 22.082C17.3086 22.082 17.9727 21.5742 17.9727 20.6172C17.9727 19.582 17.2305 18.9961 16.0391 18.9961H14.0859V22.082H15.9805ZM15.7461 16.6719C16.7812 16.6719 17.5234 16.1641 17.5234 15.2656C17.5234 14.3672 16.8203 13.8594 15.8047 13.8594H14.0859V16.6719H15.7461Z"
        fill={fill}
      />
    </svg>
  );
}

MainLogo.propTypes = {
  fill: PropTypes.string.isRequired,
};
MainLogo.defaultProps = {
  fill: 'white',
};

export default MainLogo;
