/**
 * 제작 : HanSeungHyeon
 * 날짜 : 6/20/24
 * 내용 :
 */

import React, { createContext, useEffect, useState } from 'react'; // Context 생성

// Context 생성
export const WindowSizeContext = createContext();

// Provider 컴포넌트
export const WindowSizeProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    vw: window.innerWidth,
    vh: window.innerHeight,
  });

  /**
   * 창 크기 변화 값 저장
   */
  const handleResize = () => {
    setWindowSize({
      vw: window.innerWidth,
      vh: window.innerHeight,
    });
  };

  /**
   * handleResize 이벤트 등록
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

WindowSizeProvider.propTypes = {};
WindowSizeProvider.defaultProps = {};
